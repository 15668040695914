<template>
  <h5 class="mb-1">Nutzerverwaltung</h5>
  <div class="row">
    <SelectField v-model="companyFilter" :options="options" class="inline" />
    <UserManagementButtons @createUser="users.push($event)" @createInvitation="invitations.push($event)" :companyId="companyFilter" />
  </div>

  <UserList @edit="onEdit" :users="users" :noCompany="companyFilter > 0" />
  <InvitationList v-if="invitations && companyFilter > 0" @delete="onInvitationDelete" :invitations="invitations" :companyId="companyFilter" />
</template>

<script>
import { actions, routes } from "@/constants.js";
import SelectField from "@/components/SelectField.vue";
import InvitationList from "@/components/InvitationList.vue";
import UserList from "@/components/UserList.vue";
import UserManagementButtons from "@/components/UserManagementButtons.vue";

export default {
  name: "UserManage",
  data() {
    return {
      users: null,
      invitations: null
    };
  },
  computed: {
    companies() {
      return this.$store.state.companies;
    },
    options() {
      const all = {
        name: "Alle",
        value: ""
      };

      const companies = this.companies?.map((c) => ({
        name: c.name,
        value: c.id
      })) || [];

      return [all, ...companies];
    },
    companyFilter: {
      get() {
        return this.$route.params.companyId;
      },
      set(value) {
        this.$router.push({
          params: {
            companyId: value
          }
        });
      }
    }
  },
  methods: {
    loadData() {
      const promises = [this.loadUsers(), this.loadInvitations()];
      return Promise.all(promises);
    },
    async loadUsers() {
      const id = this.companyFilter || 0;
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.COMPANY.getUsers(id)
      });

      this.users = result === null? []: result.users;
    },
    async loadInvitations() {
      const id = this.companyFilter;
      if (!id || id < 1) return;

      const result = await this.$store.dispatch(actions.GET_COMPANY_INVITATIONS, id);
      this.invitations = result === null? []: result.invitations;
    },
    onEdit(user) {
      const index = this.users.findIndex((u) => u.id == user.id);
      // eslint-disable-next-line no-console
      if (index < 0) return console.error("Failed to update user", user);

      this.users[index] = user;
    },
    onInvitationDelete(invitation) {
      this.invitations = this.invitations.filter((i) => i.token !== invitation);
    }
  },
  watch: {
    companyFilter(newValue) {
      if (newValue !== undefined) this.loadData();
    }
  },
  mounted() {
    if (this.companies === null) this.$store.dispatch(actions.GET_COMPANIES);
    this.loadData();
  },
  components: {
    SelectField,
    InvitationList,
    UserList,
    UserManagementButtons
  }
};
</script>

<style module type="scss">

</style>
